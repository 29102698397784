import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import BreadCrumb from '../components/BreadCrumb';
import Meta  from '../components/Meta';
import ProductCard from '../components/ProductCard';
import ProductCard1 from '../components/ProductCard1';
import gr from '../images/gr.svg';
import gr2 from '../images/gr2.svg';
import gr3 from '../images/gr3.svg';
import gr4 from '../images/gr4.svg';
import seeds from '../images/seeds.jpg';
import hcow from '../images/hcow.jpg';
import Container from '../components/Container';

  const OurStore = () => {
  const [grid, setGrid] = useState(4);

  const [showSubcategories, setShowSubcategories] = useState(false);

  const toggleSubcategories = () => {
    setShowSubcategories(!showSubcategories);
  };

  return (
    <>
      <Meta title={'Our Store'} />
      <BreadCrumb title='Our Store' />
      <Container class1='store-wrapper home-wrapper-2 py-5'>
        <div className='row'>
            <div className='col-3'>
              <div className='filter-card mb-3'>
                <h3 className='filter-title'>Shop By Categories</h3>
                <div>
                  <ul className='ps-0 catebackground' >
                    <ul>
                      <li onClick={toggleSubcategories}>
                        <a href='#'>Agricultural Products</a>
                        {showSubcategories && (
                          <ul>
                            <li>
                              <a href='#'>Seeds</a>
                            </li>
                            <li>
                              <a href='#'>Fertilizers</a>
                            </li>
                            <li>
                              <a href='#'>Animal Feed</a>
                            </li>
                            <li>
                              <a href='#'>Other Agricultural Products</a>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li onClick={toggleSubcategories}>
                        <a href='#'>Large Livestock</a>
                        {showSubcategories && (
                          <ul>
                            <li>
                              <a href='#'>Cattle</a>
                              <ul>
                                <li>
                                  <a href='#'>Dairy Cows</a>
                                </li>
                                <li>
                                  <a href='#'>Beef Cows</a>
                                </li>
                                <li>
                                  <a href='#'>Oxen</a>
                                </li>
                                <li>
                                  <a href='#'>Bulls</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href='#'>Buffaloes</a>
                            </li>
                            <li>
                              <a href='#'>Yaks</a>
                            </li>
                            <li>
                              <a href='#'>Camels</a>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li onClick={toggleSubcategories}>
                        <a href='#'>Small Livestock</a>
                        {showSubcategories && (
                          <ul>
                            <li>
                              <a href='#'>Sheep</a>
                              <ul>
                                <li>
                                  <a href='#'>Ewes</a>
                                </li>
                                <li>
                                  <a href='#'>Beef Cows</a>
                                </li>
                                <li>
                                  <a href='#'>Rams</a>
                                </li>
                                <li>
                                  <a href='#'>Lambs</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href='#'>Goats</a>
                              <ul>
                                <li>
                                  <a href='#'>Does</a>
                                </li>
                                <li>
                                  <a href='#'>Bucks</a>
                                </li>

                              </ul>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li onClick={toggleSubcategories}>
                        <a href='#'>Poultry</a>
                        {showSubcategories && (
                          <ul>
                            <li>
                              <a href='#'>Chickens</a>
                              <ul>
                                <li>
                                  <a href='#'>Hens</a>
                                </li>
                                <li>
                                  <a href='#'>Roosters</a>
                                </li>
                                <li>
                                  <a href='#'>Chicks</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href='#'>Turkeys</a>
                            </li>
                            <li>
                              <a href='#'>Ducks</a>
                            </li>
                            <li>
                              <a href='#'>Geese</a>
                            </li>
                            <li>
                              <a href='#'>Other Poultry</a>
                            </li>

                          </ul>
                        )}
                      </li>
                      <li onClick={toggleSubcategories}>
                        <a href='#'>Machinery and Equipment</a>
                        {showSubcategories && (
                          <ul>
                            <li>
                              <a href='#'>Tractors</a>
                            </li>
                            <li>
                              <a href='#'>Harvesters</a>
                            </li>
                            <li>
                              <a href='#'>Plows</a>
                            </li>
                            <li>
                              <a href='#'>Irrigation Systems</a>
                            </li>
                            <li>
                              <a href='#'>Fertilizer Spreaders</a>
                            </li>
                            <li>
                              <a href='#'>Other Farm Equipment</a>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  </ul>
                  </div>
                </div>
                <div className='filter-card mb-3'>
                  <h3 className='filter-title'>Filter By</h3>
                  <div>
                    <h5 className='sub-title'>Availablity</h5>
                    <div>
                      <div className='form-check'>
                        <input className='form-check-input' type="checkbox" value="" id=''/>
                        <label className='form-check-label' htmlFor=''>
                          In Stock (50)
                        </label>
                      </div>
                      <div className='form-check'>
                        <input className='form-check-input' type="checkbox" value="" id='' />
                        <label className='form-check-label' htmlFor="">
                          Out of Stock (100)
                        </label>
                      </div>
                    </div>
                    <h5 className='sub-title'>Price</h5>
                    <div className='d-flex align-items-cenet gap-10'>
                      <div className='form-floating'>
                        <input 
                          type="email" 
                          className='form-control' 
                          id='floatingInput'
                          placeholder='From'
                        />
                        <label htmlFor='floatingInput'>$ From</label>
                      </div>
                      <div className='form-floating'>
                        <input 
                          type="email" 
                          className='form-control' 
                          id='floatingInput1'
                          placeholder='To'
                        />
                        <label htmlFor='floatingInput1'>$ To</label>
                      </div>
                    </div>
                    <h5 className='sub-title'>Colors</h5>
                    <div>
                      <ul className='colors ps-0'>
                          <li></li>
                      <ul className='colors1 ps-0'>
                          <li></li>
                      </ul>
                      <ul className='colors2 ps-0'>
                          <li></li>
                      </ul>
                      <ul className='colors3 ps-0'>
                          <li></li>
                      </ul>
                      </ul>
                    </div>
                    <h5 className='sub-title'>Size</h5>
                    <div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-1'/>
                          <label className='form-check-label' htmlFor="color-1">
                            Small (50)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-2'/>
                          <label className='form-check-label' htmlFor="color-2">
                            Medium (40)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-3'/>
                          <label className='form-check-label' htmlFor="color-3">
                            Large (30)
                          </label>
                        </div>
                    </div>
                    <h5 className='sub-title'>Weight</h5>
                    <div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-1'/>
                          <label className='form-check-label' htmlFor="color-1">
                            0 - 100 kg (50)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-2'/>
                          <label className='form-check-label' htmlFor="color-2">
                            100 - 200 kg (40)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-3'/>
                          <label className='form-check-label' htmlFor="color-3">
                            200 - 300 kg (30)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-4'/>
                          <label className='form-check-label' htmlFor="color-4">
                           300 - 400 kg (30)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-5'/>
                          <label className='form-check-label' htmlFor="color-5">
                            400 - 500 kg (30)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-6'/>
                          <label className='form-check-label' htmlFor="color-6">
                            500 - 600 kg (30)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-6'/>
                          <label className='form-check-label' htmlFor="color-7">
                            600 - 700 kg (30)
                          </label>
                        </div>
                    </div>
                    <h5 className='sub-title'>Age</h5>
                    <div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-1'/>
                          <label className='form-check-label' htmlFor="color-1">
                            0 - 3 (7)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-2'/>
                          <label className='form-check-label' htmlFor="color-2">
                            3 - 6  (14)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-3'/>
                          <label className='form-check-label' htmlFor="color-3">
                            6 - 10 (28)
                          </label>
                        </div>
                    </div>
                    <h5 className='sub-title'>Country</h5>
                    <div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-1'/>
                          <label className='form-check-label' htmlFor="color-1">
                            Estonia (100)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-2'/>
                          <label className='form-check-label' htmlFor="color-2">
                            Turkey  (100)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input className='form-check-input' type="checkbox" value="" id='color-3'/>
                          <label className='form-check-label' htmlFor="color-3">
                            World (200)
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='filter-card mb-3'>
                  <h3 className='filter-title'>Product Tags</h3>
                  <div>
                     <div className='product-tags d-flex flex-wrap align-items-center gap-10'>
                          <span className='badge bg-light text-secondary rounded-3 py-2 px-3 '>
                            Agricultural Products
                          </span>
                          <span className='badge bg-light text-secondary rounded-3 py-2 px-3 '>
                            Seeds
                          </span>
                          <span className='badge bg-light text-secondary rounded-3 py-2 px-3 '>
                            In Stock
                          </span>
                          <span className='badge bg-light text-secondary rounded-3 py-2 px-3 '>
                            Weight
                          </span>
                          <span className='badge bg-light text-secondary rounded-3 py-2 px-3 '>
                            Estonia
                          </span>
                      </div>     
                  </div>
                </div>
                <div className='filter-card mb-3'>
                  <h3 className='filter-title'>Random Product</h3>
                  <div>
                    <div className='random-products mb-3 d-flex'>
                        <div className='w-50'>
                          <img src={hcow} className='img-fluid' alt='hcow'/>
                        </div>
                        <div className='w-50'>
                          <h5>
                            Holstein Friesian, Male, 4 Age, 450 Kg
                          </h5>
                          <ReactStars count={5} size={24} value={4} edit={false} activeColor="#ffd700"/>
                          <b>$ 990 </b> 
                          </div>
                    </div>
                    <div className='random-products d-flex'>
                        <div className='w-50'>
                          <img src={seeds} className='img-fluid' alt='hcow'/>
                        </div>
                        <div className='w-50'>
                          <h5>
                           Imported Fertilizer, 50 Pieces, 10 Kg
                          </h5>
                          <ReactStars count={5} size={24} value={4} edit={false} activeColor="#ffd700"/>
                        <b>$ 300 </b>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-9'>
                <div className='filter-sort-grid mb-4'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center gap-10' >
                      <p className='mb-0 d-block' style={{width:"100px"}}>Short By:</p>
                      <select name='' defaultValue={"manual"} className='form-control form-select' id=''>
                        <option value="manual">Featured</option>
                        <option value="best-selling">Best selling</option>
                        <option value="title-ascending">Alphabetically, A-Z</option>
                        <option value="title-descending">Alphabetically, Z-A</option>
                        <option value="price-ascending">Price, low to high</option>
                        <option value="title-descending">Price, high to high</option>
                        <option value="created-ascending">Date, old to new</option>
                        <option value="created-descending">Date, new to old</option>
                      </select>
                    </div>
                    <div className='d-flex align-items-center gap-10'>
                      <p className='totalproducts mb-0'>50 Product</p>
                      <div className='d-flex gap-10 align-items-center grid'>
                        <img onClick={() => {setGrid(3);}} src={gr4} className='d-block img-fluid' alt='grid'/>
                        <img onClick={() => {setGrid(4);}} src={gr3} className='d-block img-fluid' alt='grid'/>
                        <img onClick={() => {setGrid(6);}} src={gr2} className='d-block img-fluid' alt='grid'/>
                        <img onClick={() => {setGrid(12);}} src={gr} className='d-block img-fluid' alt='grid'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='products-list pb-5'>
                  <div className='d-flex gap-10 flex-wrap'>
                    <ProductCard grid={grid}/> 
                    <ProductCard1 grid={grid}/>   
                  </div>
                </div>
              </div>
        </div>
      </Container> 
    </>
  );
};
export default OurStore;