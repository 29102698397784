import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
const TermAndContions = () => {
  return (
    <>
        <Meta title={"Term And Conditions"} />
        <BreadCrumb title="Term And Conditions" />
        <Container class1='policy-wrapper home-wrapper-2 py-5'>
          <div className='row'>
              <div className='col-12'>
                <div className='policy'>
                <p className='title bold'>
                  Terms & Conditions:
                  </p>
                  <p>Welcome to our website. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions:

Intellectual Property: The content on our website, including text, images, graphics, logos, and software, is the property of our website and is protected by copyright, trademark, and other intellectual property laws. You may not use any of our content without our written permission.

Prohibited Conduct: You may not use our website for any illegal or unauthorized purpose. You may not transmit any viruses or other harmful code, or attempt to gain unauthorized access to our website or server.

User Content: You may submit content to our website, such as reviews or comments. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish, and distribute the content for any purpose.

Liability: Our website is provided on an "as is" basis without any warranties, express or implied. We are not liable for any damages, including but not limited to, direct, indirect, incidental, or consequential damages, arising from the use of our website.

Indemnification: You agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of our website or violation of these terms and conditions.

Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the state where our company is headquartered, without giving effect to any principles of conflicts of law.

Modifications: We reserve the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website.

By using our website, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use our website.</p>
                
                </div>
              </div>
          </div>
        </Container>
    </>
  )
}

export default TermAndContions