import React from 'react'
import ReactStars from 'react-rating-stars-component';
import { Link, useLocation } from 'react-router-dom';
import prodcompare from "../images/prodcompare.svg"
import wish from "../images/wish.svg";
import smcow3 from "../images/smcow3.jpg";
import smcow6 from "../images/smcow6.jpg";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
export const ProductCard = (props) => {
    const {grid} = props;
    let location = useLocation();
  return (
    <>
    <div className={` ${ location.pathname == "/product" ? `gr-${grid}` : "col-3"} `}>
        <Link to={` ${ 
                location.pathname == "/" 
                    ? "/product/id" 
                    : location.pathname == "/product/:id" 
                    ? "/product/:id" : ":id"} `} className='product-card  position-relative'>
            <div className='wishlist-icon position-absolute'>
                <button className='border-0 bg-transparent'>
                    <img src={wish} alt='wishlist'/>
                </button>
            </div>
            <div className='product-image'>
                <img src={smcow3} className='img-fluid' alt ='product image'/>
                <img src={smcow6} className='img-fluid' alt ='product image'/>
                
            </div>
            <div className='product-details'>
                <h6 className='brand'>Simmental</h6>
                <h5 className='product-title'>
                    For meat and breeding
                </h5>
                <ReactStars count={5} size={24} value={4} edit={false} activeColor="#ffd700"/>
                <p className={`description ${grid === 12 ? "d-block" : "d-none"}`}>
                    The Simmental or Swiss Fleckvieh is a Swiss breed of dual-purpose cattle. 
                    It is named after the Simmental – the valley of the Simme river – in the Bernese Oberland, in the canton of Bern in Switzerland. 
                    It is reddish in colour with white markings, and is raised for both milk and meat.
                </p>
                <p className='price'>$999.00</p>
            </div>
            <div className='action-bar position-absolute'>
                <div className='d-flex flex-column gap-15'>
                    <button className='border-0 bg-transparent'>
                        <img src={prodcompare} alt='compare'/>
                    </button>
                    <button className='border-0 bg-transparent'>
                        <img src={view} alt='view'/>
                    </button>
                    <button className='border-0 bg-transparent'>
                        <img src={addcart} alt='addcart'/>
                    </button>
                </div>
            </div>
        </Link>
    </div>
    </>
  );
};
export default ProductCard;
