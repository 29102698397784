import React from 'react'
import ReactStars from 'react-rating-stars-component';
import { Link, useLocation } from 'react-router-dom';
import prodcompare from "../images/prodcompare.svg"
import wish from "../images/wish.svg";
import hcow8 from "../images/hcow8.jpg";
import hcow4 from "../images/hcow4.jpg";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
const ProductCard1 = (props) => {
    const {grid} = props;
    let location = useLocation();
  return (
    <>
    <div className={` ${ location.pathname == "/product" ? `gr-${grid}` : "col-3"} `}>
        <Link to={` ${ 
                location.pathname == "/" 
                    ? "/product/id" 
                    : location.pathname == "/product/:id" 
                    ? "/product/:id" : ":id"} `} className='product-card  position-relative'>
            <div className='wishlist-icon position-absolute'>
                <button className='border-0 bg-transparent'>
                    <img src={wish} alt='wishlist'/>
                </button>
            </div>
            <div className='product-image'>
                <img src={hcow8} className='img-fluid' alt ='product image' />
                <img src={hcow4} className='img-fluid' alt ='product image'/>
                
            </div>
            <div className='product-details'>
                <h6 className='brand'>Holstein</h6>
                <h5 className='product-title'>
                    The most beautiful milking
                </h5>
                <ReactStars count={5} size={24} value={4} edit={false} activeColor="#ffd700"/>
                <p className={`description ${grid === 12 ? "d-block" : "d-none"}`}>
                The Holstein Friesian is an international breed or group of breeds of dairy cattle. 
                It originated in the Dutch provinces of North Holland and Friesland and in Schleswig-Holstein in northern Germany. 
                It is the dominant breed in industrial dairy farming worldwide, and is found in more than 160 countries.
                It is known by many names, among them Holstein, Friesian and Black and White.
                </p>
                <p className='price'>$999.00</p>
            </div>
            <div className='action-bar position-absolute'>
                <div className='d-flex flex-column gap-15'>
                    <button className='border-0 bg-transparent'>
                        <img src={prodcompare} alt='compare'/>
                    </button>
                    <button className='border-0 bg-transparent'>
                        <img src={view} alt='view'/>
                    </button>
                    <button className='border-0 bg-transparent'>
                        <img src={addcart} alt='addcart'/>
                    </button>
                </div>
            </div>
        </Link>
    </div>
    </>
  );
};
export default ProductCard1;
