import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
const PrivacyPolicy = () => {
  return (
    <>
        <Meta title={"Privacy Policy"} />
        <BreadCrumb title="Privacy Policy" />
        <Container class1='policy-wrapper home-wrapper-2 py-5'>
          <div className='row'>
              <div className='col-12'>
                <div className='policy'>
                  <p className='title bold'>
                  Privacy Policy: 
                  </p>
                  <p>Our website is committed to protecting the privacy of our users. We understand the importance of personal information and want to be transparent about the data we collect, how we use it, and how we protect it.

When you visit our website, we automatically collect certain data, such as your IP address, browser type, and operating system. This information helps us analyze site traffic, improve user experience, and ensure website security.

We may also collect personal information that you voluntarily provide us, such as your name, email address, phone number, and billing information. This information is used solely for the purpose of fulfilling your requests, such as processing orders or responding to inquiries. We will never share your personal information with third parties for marketing purposes.

We use cookies to enhance your browsing experience and to personalize content and advertisements. You may choose to disable cookies in your browser settings, but this may limit the functionality of the website.

We take the security of your personal information seriously and have implemented measures to protect it from unauthorized access, alteration, or disclosure. If you have any questions or concerns about our privacy policy, please contact us.
                  </p>
                </div>
              </div>
          </div>
        </Container>
    </>
  )
}

export default PrivacyPolicy