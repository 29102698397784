import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Color from '../components/Color';
import cross from "../images/cross.svg";
import hcow8 from "../images/hcow8.jpg";
import smcow2 from "../images/smcow2.jpg";
import sheep8 from "../images/sheep8.jpg";
import Container from '../components/Container';
const CompareProduct = () => {
  return (
    <>
        <Meta title={"Compare Products"} />
        <BreadCrumb title="Compare Products" />
        <Container class1='compare-product-wrapper py-5 home-wrapper-2'>
            <div className='row'>
                    <div className='col-3'>
                        <div className='compare-product-card position-relative'>
                            <img 
                                src={cross} 
                                alt='cross' 
                                className='position-absolute cross img-fluid'/>
                            <div className='product-card-image '>
                                <img src={hcow8} className='img-fluid' alt='hcow'/>
                            </div>
                            <div className='compare-product-details'>
                                <h5 className='title'>
                                    Holstein Cow
                                </h5>
                                <h6 className='price mb-3 mt-3'>$ 900</h6>
                                <div>
                                    <div className='product-detail'>
                                        <h5>Categories : </h5>
                                        <p>Animal</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Ear Tag: </h5>
                                        <p>1234</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Gender : </h5>
                                        <p>Female</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Age : </h5>
                                        <p>4 Years</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Color : </h5>
                                        <Color/>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Weight : </h5>
                                        <p>600  Kg</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Size : </h5>
                                        <p>Large</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Species : </h5>
                                        <p>Holstein Friesian</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Nutrition : </h5>
                                        <p>Fed on hay and grain</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Habitat : </h5>
                                        <p>Barn with a fenced yard</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Health Status : </h5>
                                        <p>Vaccinated and <br></br> Dewormed Regularly</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Source : </h5>
                                        <p>Bought from a local farm</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Purchase Date :  </h5>
                                        <p>May 15, 2022</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Seller : </h5>
                                        <p>Farmer John</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Domestic or Foreign : </h5>
                                        <p>Domestic</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Breeder : </h5>
                                        <p>Green Pastures<br></br> Dairy Farm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='compare-product-card position-relative'>
                            <img 
                                src={cross}
                                alt='cross' 
                                className='position-absolute cross img-fluid'/>
                            <div className='product-card-image '>
                                <img src={smcow2} className='img-fluid' alt='smcow'/>
                            </div>
                            <div className='compare-product-details'>
                                <h5 className='title'>
                                    Simmental Cow
                                </h5>
                                <h6 className='price mb-3 mt-3'>$ 1000</h6>
                                <div>
                                    <div className='product-detail'>
                                        <h5>Categories : </h5>
                                        <p>Animal</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Ear Tag: </h5>
                                        <p>1235</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Gender : </h5>
                                        <p>Male</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Age : </h5>
                                        <p>5 Years</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Color : </h5>
                                        <Color/>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Weight : </h5>
                                        <p>650  Kg</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Size : </h5>
                                        <p>Large</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Species : </h5>
                                        <p>Simmental Friesian</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Nutrition : </h5>
                                        <p>Fed on hay and grain</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Habitat : </h5>
                                        <p>Barn with a fenced yard</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Health Status : </h5>
                                        <p>Vaccinated and <br></br> Dewormed Regularly</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Source : </h5>
                                        <p>Bought from a local farm</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Purchase Date :  </h5>
                                        <p>May 15, 2020</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Seller : </h5>
                                        <p>Farmer John</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Domestic or Foreign : </h5>
                                        <p>Domestic</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Breeder : </h5>
                                        <p>Green Pastures<br></br> Dairy Farm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='compare-product-card position-relative'>
                            <img 
                                src={cross}
                                alt='cross' 
                                className='position-absolute cross img-fluid'/>
                            <div className='product-card-image '>
                                <img src={sheep8} className='img-fluid' alt='sheep'/>
                            </div>
                            <div className='compare-product-details'>
                                <h5 className='title'>
                                    Merino Sheep
                                </h5>
                                <h6 className='price mb-3 mt-3'>$ 250</h6>
                                <div>
                                    <div className='product-detail'>
                                        <h5>Categories : </h5>
                                        <p>Animal</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Ear Tag: </h5>
                                        <p>4572</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Gender : </h5>
                                        <p>Female</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Age : </h5>
                                        <p>2 Years</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Color : </h5>
                                        <Color/>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Weight : </h5>
                                        <p>50 Kg</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Size : </h5>
                                        <p>Medium</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Species : </h5>
                                        <p>Merino</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Nutrition : </h5>
                                        <p>Fed on hay and grass</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Habitat : </h5>
                                        <p>Barn with an open yard</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Health Status : </h5>
                                        <p>Vaccinated and <br></br> Dewormed Regularly</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Source : </h5>
                                        <p>Bought from a local farm</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Purchase Date :  </h5>
                                        <p>July 8, 2022</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Seller : </h5>
                                        <p>Farmer Jane</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Domestic or Foreign : </h5>
                                        <p>Domestic</p>
                                    </div>
                                    <div className='product-detail'>
                                        <h5>Breeder : </h5>
                                        <p>Green Pastures<br></br> Sheep Farm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Container>
    </>
  )
}

export default CompareProduct;