import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import cross from "../images/cross.svg";
import hcow8 from "../images/hcow8.jpg";
import smcow4 from "../images/smcow4.jpg";
import sheep8 from "../images/sheep8.jpg";
import Container from '../components/Container';
const Wishlist = () => {
  return (
    <>
        <Meta title={"Wishlist"} />
        <BreadCrumb title="Wishlist" />
        <Container class1='wishlist-wrapper home-wrapper-2 py-5'>
            <div className='row'>
                    <div className='col-3'>
                        <div className='wishlist-card position-relative'>
                            <img 
                                src={cross} 
                                alt='cross' 
                                className='position-absolute cross img-fluid'
                            />
                            <div className='wishlist-card-image '>
                                <img 
                                    src={sheep8} 
                                    className='img-fluid' 
                                    alt='sheep'
                                />
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <h5 className='title'>
                                    Merino Sheep, Female, 2 Years, 50 Kg
                                </h5>
                                <h6 className='price'>$ 250</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='wishlist-card position-relative'>
                            <img 
                                src={cross} 
                                alt='cross' 
                                className='position-absolute cross img-fluid'
                            />
                            <div className='wishlist-card-image '>
                                <img 
                                    src={hcow8}
                                    className='img-fluid' 
                                    alt='hcow'
                                />
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <h5 className='title'>
                                    Holstein Cow, Female, 4 Years, 600 Kg
                                </h5>
                                <h6 className='price'>$ 900</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='wishlist-card position-relative'>
                            <img 
                                src={cross} 
                                alt='cross' 
                                className='position-absolute cross img-fluid'
                            />
                            <div className='wishlist-card-image '>
                                <img 
                                    src={smcow4} 
                                    className='img-fluid' 
                                    alt='sheep'
                                />
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <h5 className='title'>
                                    Simmental Cow , Male, 5 Years, 650 Kg
                                </h5>
                                <h6 className='price'>$ 250</h6>
                            </div>
                        </div>
                    </div>
            </div>
        </Container>
    </>
  )
}

export default Wishlist