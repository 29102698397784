import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import {HiOutlineArrowLeft} from 'react-icons/hi';
import chick2 from "../images/chick2.jpg";
import Container from '../components/Container';
const SingleBlog = () => {
  return (
    <>
        <Meta title={"Dynamic Blog Name"} />
        <BreadCrumb title="Dynamic Blog Name" />
        <Container class1='blog-wrapper home-home-wrapper-2 py-5'>
            <div className='row'>
                    <div className='col-12'>
                        <div className='single-blog-card'>
                            <Link to="/blogs" className='d-flex align-items-center gap-10'>
                                <HiOutlineArrowLeft className='fs-4'/> Go back to Blogs
                            </Link>
                            <h3 className='title'>
                                A Beautiful Sunday Morning Renaissance
                            </h3>
                            <div>
                            <img src={chick2} className='img-fluid w-100 my-4' alt='blog'/>
                            <p>
                                    <br></br>Avian Influenza Outbreak in Asia Raises Concerns of Pandemic Potential
                                An outbreak of avian influenza, commonly known as bird flu, has been reported in several countries in Asia. 
                                The H5N1 strain of the virus has been identified as the cause of the outbreak, which has infected both 
                                domesticated and wild birds, as well as a small number of humans. While the virus is not easily transmissible 
                                between humans, concerns have been raised about the potential for the virus to mutate and become more contagious, 
                                potentially leading to a pandemic. Health officials are urging people to take precautions, such as avoiding contact 
                                with sick birds and thoroughly cooking poultry products, to prevent the spread of the virus. Efforts are also underway 
                                to develop a vaccine for the H5N1 strain, but experts warn that it could take several years before a safe and effective
                                vaccine is widely available. The World Health Organization (WHO) is closely monitoring the situation and working with 
                                countries to contain the outbreak and prevent further spread of the virus.
                            </p>    
                            </div>
                        </div>
                    </div>
            </div>
        </Container>
    </>
  )
}

export default SingleBlog