import React from 'react'
import { Link } from 'react-router-dom'
import chick2 from "../images/chick2.jpg";
export const BlogCard = () => {
  return (
        <div className='blog-card'>
            <div className='card-image'>
                <img src={chick2} className='img-fluid w-100' alt="blog"/>
            </div>
            <div className='blog-content'>
                <p className='date'>1 March, 2023 </p>
                <h5 className='title'> Bird flu puts chickens at risk </h5>
                <p className='desc'>
                Avian influenza, or “bird flu,” is a respiratory disease of birds caused by influenza A viruses. 
                Wild birds, such as ducks, gulls, and shorebirds, can carry and spread these viruses but may show....
                </p>
                <Link to="/blog/:id" className='button'>
                    Read More
                </Link>
            </div>
        </div>
  );
};

export default BlogCard;        
