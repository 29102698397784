import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import {AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import {BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import Container from '../components/Container';
const Contact = () => {
  return (
      <>
        <Meta title={"Contact Us"} />
        <BreadCrumb title="Contact Us" />
        <Container class1='contact-wrapper py-5 home-wrapper-2'>
          <div className='row'>
              <div className='col-12'>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24485.87635793001!2d30.039629453425064!3d39.902575233387104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cbf14e2a716c0b%3A0xd0712f7959a335f5!2zQm96w7x5w7xrLCBCaWxlY2lr!5e0!3m2!1str!2str!4v1680142681086!5m2!1str!2str" 
                  width="600" 
                  height="450" 
                  className='border-0 w-100' 
                  allowFullScreen="" 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
              <div className='col-12 mt-5'>
                <div className='contact-inner-wrapper d-flex justify-content-between'>
                  <div>
                    <h3 className='contact-title mb-4'>Contact</h3>
                    <form action='' className='d-flex flex-column gap-15'>
                      <div> 
                        <input type='text' className='form-control' placeholder='Name'/>
                      </div>
                      <div>
                        <input type='email' className='form-control' placeholder='Email' />
                      </div>
                      <div>
                        <input type='tel' className='form-control' placeholder='Mobile Number' />
                      </div>
                      <div>
                        <textarea name='' id='' className='w-100 form-control' cols='30' rows='10' placeholder='Comments'></textarea>
                      </div>
                    </form>
                  </div>
                  <div>
                    <h3 className='contact-title mb-4' >Get in touch with us</h3>
                    <div>
                      <ul className='ps-0'>
                        <li className='mb-3 d-flex gap-15 align-items-center'>
                          <AiOutlineHome className='fs-5'/>
                          <address className='mb-0'>
                            Hno : 314 Atatürk,
                            Istanbul , Turkey
                            Code: 34000
                          </address>
                        </li>
                        <li className='mb-3 d-flex gap-15 align-items-center'>
                          <BiPhoneCall className='fs-5'/>
                          <a href='tel:+90 5555555555'> +90 5555555555</a>
                        </li>
                        <li className='mb-3 d-flex gap-15 align-items-center'>
                          <AiOutlineMail className='fs-5'/>
                          <a href='mailto:contact@faaion.com'>contact@faaion.com</a>
                        </li>
                        <li className='mb-3 d-flex gap-15 align-items-center'>
                          <BiInfoCircle className='fs-5'/>
                          <p className='mb-0'> Monday - Sunday 10 Am - 8 PM</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>          
          </div>
        </Container>
      </>
  )
}

export default Contact