import React, { useState } from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import ProductCard from '../components/ProductCard';
import ProductCard1 from '../components/ProductCard1';
import ReactStars from"react-rating-stars-component";
import ReactImageZoom from 'react-image-zoom';
import { TbGitCompare } from 'react-icons/tb';
import {AiOutlineHeart} from 'react-icons/ai';
import smcow8 from "../images/smcow8.jpg";
import Container from '../components/Container';
const SingleProduct = () => {
    const [orderProduct, setorderedProduct] = useState(true);
    const copyToClipboard = (text) => {
        console.log('text', text)
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
      }
    
    const props = {
        width: 400, 
        height: 400, 
        zoomWidth: 600,
        img: "https://britishsimmental.co.uk/wp-content/uploads/2021/09/Berryleys-002.jpg"};
  return (
    <>
        <Meta title={"Product Name"} />
        <BreadCrumb title="Product Name" />
        <Container class1='main-product-wrapper py-5 home-wrapper-2'>
            <div className='row'>
                    <div className='col-6'>
                        <div className='main-product-image '>
                            <div>
                                <ReactImageZoom {...props} />
                            </div>
                        </div>
                        <div className='other-product-images d-flex flex-wrap gap-15'>
                            <div>
                                <img src={smcow8} className='img-fluid' alt='smcow'/>
                            </div>
                            <div>
                                <img src={smcow8} className='img-fluid' alt='smcow'/>
                            </div>
                            <div>
                                <img src={smcow8} className='img-fluid' alt='smcow'/>
                            </div>
                            <div>
                                <img src={smcow8} className='img-fluid' alt='smcow'/>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='main-product-details'>
                            <div className='border-bottom'>
                            <h3 className='title'>
                                Simmental Cow , Female, 5 Years, 650 Kg
                            </h3>
                            </div>
                            <div className='border-bottom py-3'>
                                <p className='price'>$ 100</p>
                                <div className='d-flex align-items-center gap-10'>
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            value={4}
                                            edit={false}
                                            activeColor="#ffd700"
                                        />
                                        <p className='mb-0 t-review'>(2 Reviews)</p>

                                </div>
                                <a className='review-btn' href='#review'>Write a Review</a>
                            </div>
                            <div className='py-3'>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Category : </h3> 
                                    <p className='product-data'>Animal</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Type  : </h3> 
                                    <p className='product-data'>Simmental</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Ear Tag: : </h3> 
                                    <p className='product-data'>1234</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Gender  : </h3> 
                                    <p className='product-data'>Female</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Age  : </h3> 
                                    <p className='product-data'>4 Years</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Weight  : </h3> 
                                    <p className='product-data'>600</p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-2'>
                                    <h3 className='product-heading'>Availablity  : </h3> 
                                    <p className='product-data'>In Stock</p>
                                </div>
                                <div className='d-flex gap-10 flex-column mt-2 mb-3'>
                                    <h3 className='product-heading'>Size  : </h3> 
                                    <div className='d-flex flex-wrap gap-15'>
                                        <span className='badge border border-1 bg-white text-dark border-secondary'>
                                            S
                                        </span>
                                        <span className='badge border border-1 bg-white text-dark border-secondary'>
                                            M
                                        </span>
                                        <span className='badge border border-1 bg-white text-dark border-secondary'>
                                            L
                                        </span>
                                    </div>
                                </div>
                                <div className='d-flex gap-10 flex-column mt-2 mb-3'>
                                    <h3 className='product-heading'>Color  : </h3> 
                                    <div className='d-flex flex-wrap gap-30'>
                                    <ul className='colors ps-2'>
                                        <li></li>
                                    </ul>
                                    <ul className='colors2 ps-0'>
                                        <li></li>
                                    </ul>
                                    <ul className='colors3 ps-0'>
                                        <li></li>
                                    </ul>

                                    </div>
                                </div>
                                <div className='d-flex gap-15 align-items-center flex-row mt-2 mb-3'>
                                    <h3 className='product-heading'>Quantity  : </h3> 
                                    <div className=''>
                                        <input type='number'
                                        name=''
                                        min={1}
                                        max={100}
                                        className='form-control'
                                        style={{width: "70px"}}
                                        id=''/>
                                    </div>
                                    <div className='d-flex align-items-center gap-30 ms-5'>
                                        <button className='button border-0' type='submit'>
                                            Add to Cart
                                        </button>
                                        <button className='button signup'>Buy It Now</button>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-15'>
                                    <div>
                                        <a href=''>
                                            <TbGitCompare  className='fs-5 me-2'/> 
                                        Add to Compere
                                        </a>
                                    </div>
                                    <div>
                                        <a href=''> 
                                            <AiOutlineHeart className='fs-5 me-2'/>
                                        Add to Wishlist
                                        </a>
                                    </div>
                                </div>
                                <div className='d-flex gap-10 flex-column my-3'>
                                    <h3 className='product-heading'>Shipping & Returnns :</h3>
                                    <p className='product-data'>
                                        Free shipping and returns available on all orders! <br/>{""}
                                        We ship all US domestic orders within
                                        <b> 5-10 business days!</b>
                                    </p>
                                </div>
                                <div className='d-flex gap-10 align-items-center my-3'>
                                    <h3 className='product-heading'>Product Link :</h3>
                                    <a href="#" onClick={() => {
                                        copyToClipboard("https://britishsimmental.co.uk/wp-content/uploads/2021/09/Berryleys-002.jpg");}}>
                                        Copy Product Link
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Container>  
        <Container class1='description-wrapper home-wrapper-2 py-5'>
            <div className='row'>
                    <div className='col-12'>
                        <h4>Description</h4>
                        <div className='bg-white p-3'>
                            <p> Welcome to our online store! We are committed to providing high-quality products and exceptional customer service. Our product range includes a variety of items, from fashion accessories to home decor.
                                We strive to provide accurate product descriptions and images, but please note that colors may vary slightly due to monitor settings. If you have any questions about a product, please contact us before making a purchase.
                                We offer free standard shipping on orders over $50 and a 30-day return policy for your convenience. Our website is secure and your personal information is protected.
                                Thank you for choosing our store for your shopping needs. We appreciate your business and look forward to serving you.
                            </p>
                        </div>
                    </div>
            </div>
        </Container>
        <Container class1='reviews-wrapper home-wrapper-2'>
            <div className='row'>
                    <div className='col-12'>
                        <h3 id='review'>Reviews</h3>
                        <div className='review-inner-wrapper'>
                            <div className='review-head d-flex justify-content-between align-items-end'>
                                <div>
                                    <h4 className='mb-2'>Customer Reviews</h4>
                                    <div className='d-flex align-items-center gap-10'>
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            value={4}
                                            edit={false}
                                            activeColor="#ffd700"
                                        />
                                        <p className='mb-0'>Based on 2 Reviews</p>
                                    </div>
                                </div>
                                {orderProduct && (
                                <div>
                                    <a 
                                        className='text-dark text-decoration-underline' 
                                        href=''> 
                                        Write a Review
                                    </a>
                                </div>
                                )}
                            </div>
                            <div className='review-form py-4'>
                                <h4>Write a Review</h4>
                                <form action='' className='d-flex flex-column gap-15'>
                                    <div>
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            value={4}
                                            edit={true}
                                            activeColor="#ffd700"/>
                                    </div>
                                    <div>
                                        <textarea 
                                            name='' 
                                            id='' 
                                            className='w-100 form-control'
                                            cols="30"
                                            rows="4"
                                            placeholder='Comments'>
                                            </textarea>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button className='button border-0'>Submit Review</button>
                                    </div>
                                </form>
                            </div>
                            <div className='reviews mt-4'>
                                <div className='review'>
                                    <div className='d-flex gap-10 align-items-center'>
                                        <h6 className='mb-0'>Navdeep</h6>
                                        <ReactStars
                                                count={5}
                                                size={24}
                                                value={4}
                                                edit={false}
                                                activeColor="#ffd700"
                                            />
                                    </div>
                                    <p className='mt-3'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec mauris ac urna fermentum cursus. Nulla facilisi. Donec at risus vel nisi convallis laoreet. Sed vitae tincidunt augue. Sed in convallis libero. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Container>
        <Container class1='populer-wrapper py-5 home-wrapper-2'>
            <div className='row'>
                <div className='col-12'>
                <h3 className='section-heading'>Our Populer Products</h3>
                </div>
            </div>
            <div className='row'>
                <ProductCard/>
                <ProductCard1/>
                <ProductCard1/>
            </div>
        </Container>
    </>
  )
}

export default SingleProduct