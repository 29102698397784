import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
const RefundPolicy = () => {
  return (
    <>
        <Meta title={"Refund Policy"} />
        <BreadCrumb title="Refund Policy" />
        <Container class1='policy-wrapper home-wrapper-2 py-5'>
          <div className='row'>
              <div className='col-12'>
                <div className='policy'>
                <p className='title bold'>
                  Refund Policy: 
                  </p>
                  <p>We want our customers to be completely satisfied with their purchases. If for any reason you are not satisfied, you may return the item within 30 days of receiving it for a full refund. The item must be unused and in its original packaging.

To request a return, please contact us and provide your order number, the item you wish to return, and the reason for the return. We will provide you with instructions on how to return the item.

Once we receive the returned item, we will inspect it to ensure that it meets our return policy requirements. Refunds will be processed within 5-7 business days of receiving the returned item. Shipping costs are non-refundable.
</p>
                </div>
              </div>
          </div>
        </Container>
    </>
  )
}

export default RefundPolicy