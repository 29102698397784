import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
const ShippingPolicy = () => {
  return (
    <>
        <Meta title={"Shipping Policy"} />
        <BreadCrumb title="Shipping Policy" />
        <Container class1='policy-wrapper home-wrapper-2 py-5'>
          <div className='row'>
              <div className='col-12'>
                <div className='policy'>
                <p className='title bold'>
                  Shipping Policy: 
                  </p>
                  <p>We offer free standard shipping on all orders over $50. Orders are typically processed within 1-2 business days and shipped via a reputable carrier such as USPS, FedEx, or UPS. Shipping times vary based on the shipping method selected and the destination.

We are not responsible for any delays in shipping due to carrier issues or weather conditions. If your package is lost or stolen during shipping, please contact us and we will work with the carrier to resolve the issue.

Please note that we do not ship to P.O. boxes or APO/FPO addresses. International shipping is available, but additional fees may apply. Customers are responsible for any customs, duties, or taxes incurred during international shipping.</p>
                </div>
              </div>
          </div>
        </Container>
    </>
  )
}

export default ShippingPolicy