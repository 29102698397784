import React from 'react';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { BlogCard } from '../components/BlogCard';
import { ProductCard } from '../components/ProductCard';
import { SpecialProduct } from '../components/SpecialProduct';
import ProductCard1 from '../components/ProductCard1';
import Container from "../components/Container";
import { services } from '../utils/Data';
const Home = () => {
  return (
    <>
      <Container class1='home-wrapper-1 py-5'>
        <div className="row">
                <div className="col-6">
                  <div className="main-banner position-relative">
                    <img
                      src="images/main-banner-1.jpg"
                      className="img-fluid rounded-3"
                      alt="main banner"
                    />
                    <div className="main-banner-content position-absolute">
                      <h4>THE MOST SUITABLE DAIRY COWS</h4>
                      <h5>For Farmers</h5>
                      <p>From $999.00 or $9999.00</p>
                      <Link className="button">BUY NOW</Link>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center">
                    <div className="small-banner position-relative">
                      <img
                        src="images/catbanner-01.jpg"
                        className="img-fluid rounded-3"
                        alt="main banner"
                      />
                      <div className="small-banner-content position-absolute">
                        <h4>BEST </h4>
                        <h5>SEEDS</h5>
                      </div>
                    </div>
                    <div className="small-banner position-relative">
                      <img
                        src="images/catbanner-02.jpg"
                        className="img-fluid rounded-3"
                        alt="main banner"
                      />
                      <div className="small-banner-content position-absolute">
                        <h4>NEW ARRIVAL</h4>
                        <h5>MACHINERY</h5>
                      </div>
                    </div>
                    <div className="small-banner position-relative">
                      <img
                        src="images/catbanner-03.jpg"
                        className="img-fluid rounded-3"
                        alt="main banner"
                      />
                      <div className="small-banner-content position-absolute " >
                        <h4>NEW ARRIVAL</h4>
                        <h5>FERTILIZERS</h5>
                      </div>
                    </div>
                    <div className="small-banner position-relative">
                      <img
                        src="images/catbanner-04.jpg"
                        className="img-fluid rounded-3"
                        alt="main banner"
                      />
                      <div className="small-banner-content position-absolute " >
                        <h4>NEW ARRIVAL</h4>
                        <h5>ANIMAL FEED</h5>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </Container>
      <Container class1='home-wrapper-2 py-5'>
        <div className='row'>
            <div className='col-12'>
              <div className="servies d-flex align-items-center justify-content-between">
                {services?.map((i, j)=>{
                  return (
                    <div className='d-flex align-items-center gap-15' key={j}>
                      <img src={i.image} alt='services'/>
                      <div>
                        <h6>{i.title}</h6>
                        <p className='mb-0'>{i.tagline}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
        </div>
      </Container>
      <Container class1='home-wrapper-2 py-5'> 
          <div className='row'>
            <div className='col-12'>
              <div className='categories d-flex justify-content-between flex-wrap align-items-center'>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Milk</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/milk1.jpg' alt='milk'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Eggs</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/eggs4.jpg' alt='eggs'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Seeds</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/seeds.jpg' alt='seeds'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Chicken</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/chick.jpg' alt='chick'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Fertilizer</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/Fertilizer.jpg' alt='Fertilizer'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Animal Feed</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/animalfeed.jpg' alt='animalfeed'/>
                </div>
                <div className='d-flex gap align-items-center'>
                  <div>
                    <h6>Ducks </h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/ducks.jpg' alt='ducks'/>
                </div>
                <div className='d-flex gap align-items-center ' >
                  <div>
                    <h6>Fish</h6>
                    <p>10 Items</p>
                  </div>
                  <img src='images/fish2.jpg' alt='fish'/>
                </div>
              </div>
            </div>
          </div>
      </Container>
      <Container class1='featured-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Featured Collection</h3>
            </div>
            <ProductCard1/>
            <ProductCard1/>
            <ProductCard1/>
            <ProductCard1/>
          </div>
      </Container>
      <Container class1='famous-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-3'>
              <div className='famous-card famous-content position-relative'>
                <img src='images/famous-12.webp' className='img-fluid' alt='famous'/>
                <div className='famous-content position-absolute'>
                  <h5>Simmental Cattle</h5>
                  <h6>For Eat</h6>
                  <p>From $99 or $999</p>
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='famous-card famous-content position-relative'>
                <img src='images/famous-12.webp' className='img-fluid' alt='famous'/>
                <div className='famous-content position-absolute'>
                  <h5>Simmental Cattle</h5>
                  <h6>For Eat</h6>
                  <p>From $99 or $999</p>
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='famous-card famous-content position-relative'>
                <img src='images/famous-12.webp' className='img-fluid' alt='famous'/>
                <div className='famous-content position-absolute'>
                  <h5>Simmental Cattle</h5>
                  <h6>For Eat</h6>
                  <p>From $99 or $999</p>
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='famous-card famous-content position-relative'>
                <img src='images/famous-12.webp' className='img-fluid' alt='famous'/>
                <div className='famous-content position-absolute'>
                  <h5>Simmental Cattle</h5>
                  <h6>For Eat</h6>
                  <p>From $99 or $999</p>
                </div>
              </div>
            </div>
          </div>
      </Container>
      <Container class1='special-wrapper py-5 home-wrapper-2' >
            <div className='row'>
              <div className='col-12'>
                <h3 className='section-heading'>Special Products</h3>
              </div>
            </div>
            <div className='row'>
              <SpecialProduct />
              <SpecialProduct />
              <SpecialProduct />
              <SpecialProduct />
            </div>
      </Container>
      <Container class1='populer-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Our Populer Products</h3>
            </div>
          </div>
            <div className='row'>
              <ProductCard/>
              <ProductCard/>
              <ProductCard/>
              <ProductCard/>
          </div>
      </Container>
      <Container class1='marque-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='marquee-inner-wrapper card-wrapper'>
                <Marquee className='d-flex'>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-01.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-02.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-03.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-04.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-05.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-06.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-07.png' alt='brand'/>
                  </div>
                  <div  className='mx-4 w-25'>
                    <img src='images/brand-08.png' alt='brand'/>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
      </Container>
      <Container class1='blog-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Our Latest Blogs</h3>
            </div>
            <div className='row'>
              <div className='col-3'>
                <BlogCard/>
              </div>
              <div className='col-3'>
                <BlogCard/>
              </div>
              <div className='col-3'>
                <BlogCard/>
              </div>
              <div className='col-3'>
                <BlogCard/>
              </div>
            </div>
          </div>
      </Container>
    </>
  );
};

export default Home;