import React from 'react'

const Color = () => {
  return (
    <>
        <ul className='colors1 ps-2'>
            <li></li>
        </ul>
        <ul className='colors4 ps-0'>
            <li></li>
        </ul>
        <ul className='colors3 ps-0'>
            <li></li>
        </ul>
    </>
  )
}

export default Color